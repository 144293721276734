import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import { SectionHeading } from "components/misc/Headings";
import MiniCenteredFooter from "components/footers/MiniCenteredFooter";
import { Link } from "react-router-dom";
import { Subheading } from "components/misc/Headings";

const HeadingRow = tw.div`flex`;
const HeadingContainer = tw.div`mb-1`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Heading2 = tw(Subheading)`text-gray-900 mb-10`;

const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;
export default ({ headingText = "Privacy Policy" }) => {
  return (
    <AnimationRevealPage>
      <Header />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <HeadingContainer>
              <Heading>Datenschutzerklärung</Heading>
              <Heading2>Verantwortlicher für den Datenschutz</Heading2>
            </HeadingContainer>
          </HeadingRow>
          <Text>
            {/*<div>Martin Bissiger<br />
            Küpfgasse 13<br />
            2543 Lengnau<br />
            Bern, Schweiz<br />*/}
            <div>E-Mail: info@m-solutions.ch</div>
            <h2>Allgemeines</h2>
            <p>Der Schutz Ihrer persönlichen Daten ist uns ein wichtiges Anliegen. In dieser Datenschutzerklärung informieren wir Sie darüber, welche personenbezogenen Daten wir von Ihnen erheben, wenn Sie unsere Website besuchen und unser Angebot nutzen. Wir verarbeiten Ihre Daten ausschließlich auf Grundlage der gesetzlichen Bestimmungen.</p>
            <h2>Erhebung und Verarbeitung personenbezogener Daten</h2>
            <p>Personenbezogene Daten sind Informationen, die sich auf eine identifizierte oder identifizierbare natürliche Person beziehen. Wir erheben und verarbeiten personenbezogene Daten von Ihnen, wenn Sie unser Kontaktformular ausfüllen, um uns zu kontaktieren oder um Informationen über unseren PC-Bau-Service anzufordern. Die Daten, die wir erheben, können Ihren Namen, Ihre E-Mail-Adresse, Ihre Telefonnummer und andere Informationen umfassen, die Sie uns freiwillig mitteilen.</p>
            <h2>Zweck der Datenverarbeitung</h2>
            <p>Wir verarbeiten Ihre personenbezogenen Daten zu folgenden Zwecken:</p>
            <ul>
                <li>Um Ihre Anfragen zu beantworten und Ihnen Informationen über unseren PC-Bau-Service zu geben</li>
                <li>Um Verträge mit Ihnen abzuschließen und diese auszuführen</li>
                <li>Um Ihnen Kundensupport zu bieten</li>
                <li>Um unsere Website und unseren Service zu verbessern</li>
            </ul>
            <h2>Rechtsgrundlage der Verarbeitung</h2>
            <p>Die Verarbeitung Ihrer personenbezogenen Daten erfolgt auf der Grundlage von:</p>
            <ul>
                <li>Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO), wenn Sie uns diese über das Kontaktformular oder auf andere Weise erteilt haben</li>
                <li>Der Erfüllung von Verträgen oder vorvertraglichen Maßnahmen (Art. 6 Abs. 1 lit. b DSGVO), wenn Sie unseren PC-Bau-Service in Anspruch nehmen</li>
                <li>Unseren berechtigten Interessen (Art. 6 Abs. 1 lit. f DSGVO), um unsere Website und unseren Service zu verbessern und um Ihnen Kundensupport zu bieten</li>
            </ul>
            <h2>Datenübermittlung</h2>
            <p>Wir geben Ihre personenbezogenen Daten nicht ohne Ihre ausdrückliche Zustimmung an Dritte weiter, es sei denn, dies ist gesetzlich vorgeschrieben oder für die Abwicklung von Verträgen erforderlich.</p>
            <h2>Speicherdauer</h2>
            <p>Wir speichern Ihre personenbezogenen Daten nur so lange, wie dies für die in dieser Datenschutzerklärung genannten Zwecke erforderlich ist oder wie es die vom Gesetzgeber vorgeschriebenen Aufbewahrungsfristen vorsehen.</p>

            <h2>Verwendung von Cookies</h2>
            <p>Wir verwenden Cookies auf unserer Website, um unsere Dienste bereitzustellen und zu verbessern, sowie um Ihnen eine personalisierte Erfahrung zu ermöglichen. Cookies sind kleine Dateien, die auf Ihrem Computer oder Gerät gespeichert werden, wenn Sie eine Website besuchen. Sie helfen uns, die Nutzung unserer Website zu analysieren und zu verbessern, indem sie uns beispielsweise Informationen darüber geben, welche Seiten Sie besucht haben oder wie lange Sie auf unserer Website verweilt haben.</p>
<p>Wenn Sie unsere Website besuchen, werden Sie über die Verwendung von Cookies informiert und haben die Möglichkeit, der Verwendung von Cookies zuzustimmen oder sie abzulehnen. Wenn Sie sich entscheiden, Cookies abzulehnen, kann es jedoch sein, dass einige Funktionen unserer Website für Sie nicht verfügbar sind.</p> 

            <h2>Ihre Rechte</h2>
            <p>Sie haben das Recht auf Auskunft, Berichtigung, Löschung, Einschränkung der Verarbeitung, Datenübertragbarkeit, Widerruf und Widerspruch in Bezug auf Ihre bei uns gespeicherten personenbezogenen Daten. Wenn Sie der Meinung sind, dass die Verarbeitung Ihrer Daten gegen das Datenschutzrecht verstößt oder Ihre datenschutzrechtlichen Ansprüche in anderer Weise verletzt wurden, können Sie sich bei der zuständigen Aufsichtsbehörde beschweren. In der Schweiz ist dies der Eidgenössische Datenschutz- und Öffentlichkeitsbeauftragte (EDÖB).</p>
            <h2>Datensicherheit</h2>
            <p>Wir setzen technische und organisatorische Sicherheitsmaßnahmen ein, um Ihre personenbezogenen Daten gegen unbeabsichtigte oder unrechtmäßige Zerstörung, Verlust, Veränderung, unbefugte Offenlegung oder unbefugten Zugriff zu schützen. Wir überprüfen und aktualisieren unsere Sicherheitsmaßnahmen regelmäßig, um ein hohes Sicherheitsniveau zu gewährleisten.</p>
            <h2>Änderungen der Datenschutzerklärung</h2>
            <p>Wir behalten uns vor, diese Datenschutzerklärung bei Bedarf zu aktualisieren, um sie an geänderte rechtliche Rahmenbedingungen oder bei Änderungen unseres Angebots anzupassen. Die jeweils aktuelle Fassung der Datenschutzerklärung kann auf unserer Website eingesehen werden.</p>
            <h2>Kontakt</h2>
            <p>Wenn Sie Fragen zum Datenschutz haben oder Ihre Rechte in Bezug auf Ihre personenbezogenen Daten ausüben möchten, können Sie uns unter den im Abschnitt "Verantwortlicher für den Datenschutz" angegebenen Kontaktdaten erreichen.</p>
            <p>Datum der letzten Aktualisierung: 15.04.2023</p>
          </Text>
        </ContentWithPaddingXl>
      </Container>
      <MiniCenteredFooter />
    </AnimationRevealPage>
  );
};
