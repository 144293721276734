import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ImageSlider = ({ images }) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 1500,
    slidesToShow: 4,
    autoplay: true,
    cssEase: 'linear',
    autoplaySpeed: 2000,
    pauseOnHover: true,
    pauseOnFocus: true,
    pauseOnDotsHover: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const slideImageStyle = {
    width: "100%",
    transition: "transform 0.3s",
    padding: "0 5px", // Add left and right padding to create a margin between images
  };


  const handleMouseEnter = (event) => {
    event.target.style.transform = "scale(1.2)";
    event.target.style.cursor = "pointer";
  };

  const handleMouseLeave = (event) => {
    event.target.style.transform = "";
  };

return (
    <div style={{ width: "100%" }}>
        <Slider {...settings}>
        {images.map((image, index) => (
            <div key={index}>
            <img
                src={image}
                alt={`slide-${index}`}
                style={slideImageStyle}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            />
            </div>
        ))}
        </Slider>
    </div>
    );
};

export default ImageSlider;