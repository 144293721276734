import React from "react";
import GlobalStyles from 'styles/GlobalStyles';
import { css } from "styled-components/macro"; //eslint-disable-line
import CookieConsent from "react-cookie-consent";

import SaaSProductLandingPage from "demos/SaaSProductLandingPage.js";
import PrivacyPolicy from "pages/PrivacyPolicy";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

export default function App() {
  const handleAccept = () => {
    // Do something when user accepts cookies
  };

  return (
    <>
      <GlobalStyles />
      <Router>
        <Routes>
          <Route path="/" element={<SaaSProductLandingPage />} />
          <Route path="/datenschutzerklaerung" element={<PrivacyPolicy />} />
        </Routes>
      </Router>
      <CookieConsent
        location="bottom"
        buttonText="Ich akzeptiere"
        cookieName="myCookieName"
        style={{ background: "#586989", display: "flex", alignItems: "center", justifyContent: "center", position: "fixed", bottom: "0", left: "0", right: "0", padding: "10px", zIndex: "999" }}
        buttonStyle={{ background: "#6415ff", color: "#fff", fontSize: "16px", padding: "10px 20px", borderRadius: "4px", marginLeft: "20px" }}
        expires={150}
      >
        {"Diese Webseite verwendet Cookies. Erfahren Sie mehr. "}
        <a href="/datenschutzerklaerung" style={{ color: "#fff", textDecoration: "underline" }}>Zur Datenschutzerklärung</a>
      </CookieConsent>
    </>
  );
}
