// loadImages.js

function importAll(r) {
  return r.keys().map(r);
}

// Fisher-Yates shuffle function
function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
}

const images = importAll(require.context('../../images/pcs', false, /\.(png|jpe?g|svg)$/));

//shuffleArray(images); // Shuffle the images array

export default images;
